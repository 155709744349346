/**
 * 이 파일은 특정 업체의 기능을 활성화/비활성화하는 데 사용됩니다.
 * 
 * 설명:
 * 이 파일에는 다른 업체에 대한 특정 기능의 가용성을 제어하는 데 사용할 수 있는 변수가 포함되어 있습니다.
 * 이러한 변수의 값을 수정함으로써 특정 업체에 대한 특정 기능을 활성화하거나 비활성화할 수 있습니다.
 * 이를 통해 다른 업체의 요구 사항에 따라 응용 프로그램의 동작을 사용자 정의할 수 있는 유연성을 제공합니다.
 * 
 * 사용법:
 * 이 파일을 사용하려면 여기에서 정의된 변수를 코드베이스의 다른 부분으로 가져올 수 있습니다.
 * 이러한 변수를 참조함으로써 업체에 따라 조건부로 특정 기능을 활성화하거나 비활성화할 수 있습니다.
 * 이를 통해 단일 코드베이스를 다른 클라이언트나 조직에 맞게 사용자 정의할 수 있습니다.
 * 
 * 참고:
 * 이러한 변수의 값이 요구 사항에 맞게 제대로 관리되고 업데이트되도록 주의해야 합니다.
 * 코드에 하드코딩된 값을 피하고 대신 이러한 변수에 의존하여 구성 가능성을 확보하는 것이 중요합니다.
 */

export const customFunctions = {
    // ADD_SETTING_EQUIPMENT_AUTOCOMPLETE: 설비 선택하는 AutoComplete 컴포넌트 추가 여부
    ADD_SETTING_EQUIPMENT_AUTOCOMPLETE: process.env.REACT_APP_ADD_SETTING_EQUIPMENT_AUTOCOMPLETE === 'true' || false,
    // ADD_SETTING_ITEM_AUTOCOMPLETE: 품목 선택하는 AutoComplete 컴포넌트 추가 여부
    ADD_SETTING_ITEM_AUTOCOMPLETE: process.env.REACT_APP_ADD_SETTING_ITEM_AUTOCOMPLETE === 'true' || false,

    // ADD_INPUT_ERP_WORK_ORDER: 생산관리 - 연동) 생산계획 가져오기 기능 추가 여부
    ADD_INPUT_ERP_WORK_ORDER: process.env.REACT_APP_ADD_INPUT_ERP_WORK_ORDER === 'true' || false,
    // ADD_ERP_WORK_RECORD: 생산관리 - 연동) 작업 실적 전달하기 기능 추가 여부
    ADD_ERP_WORK_RECORD: process.env.REACT_APP_ADD_ERP_WORK_RECORD === 'true' || false,
    // ADD_INPUT_ERP_OUTSOURCING_WORK_ORDER: 생산관리 - 연동) 외주 생산계획 가져오기 기능 추가 여부
    ADD_INPUT_ERP_OUTSOURCING_WORK_ORDER: process.env.REACT_APP_ADD_INPUT_ERP_OUTSOURCING_WORK_ORDER === 'true' || false,
    // ADD_ERP_WORK_RECORD_OUTSOURCING: 생산관리 - 연동) 외주 작업 실적 전달하기 기능 추가 여부
    ADD_ERP_WORK_RECORD_OUTSOURCING: process.env.REACT_APP_ADD_ERP_WORK_RECORD_OUTSOURCING === 'true' || false,

    // ADD_SETTING_WORK_DATE_SELECT: 생산관리 - 작업날짜 선택 기능 추가 여부
    ADD_SETTING_WORK_DATE_SELECT: process.env.REACT_APP_ADD_SETTING_WORK_DATE_SELECT === 'true' || false,

    // ADD_WORK_ID_COLUMN: 생산관리 - 작업 번호 열 추가 여부
    ADD_WORK_ID_COLUMN: process.env.REACT_APP_ADD_WORK_ID_COLUMN === 'true' || false,
    // ADD_EXTERNAL_OUTSOURCING_COLUMN: 생산관리 - 사외 외주 여부 컬럼 추가 여부
    ADD_EXTERNAL_OUTSOURCING_COLUMN: process.env.REACT_APP_ADD_EXTERNAL_OUTSOURCING_COLUMN === 'true' || false,
    // ADD_DESCRIPTION_COLUMN: 생산관리 - 비고 컬럼 추가 여부
    ADD_DESCRIPTION_COLUMN: process.env.REACT_APP_ADD_DESCRIPTION_COLUMN === 'true' || false,


    // ...다른 기능에 대한 추가 변수를 여기에 추가할 수 있습니다.
}