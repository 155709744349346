import { mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { usePostProductionPlanInputERPWorkOrder } from "@/api/productionPlan/usePostProductionPlanGetQuery";
import { setERPWorkRecord } from "@/api/works/useWorkRecordQuery";
import { customFunctions } from "@/config/customFunction";
import { WorkInputForm } from "@/features/inventory/components/Form/WorkInputForm";
import { LeftActionBar, RightActionBar } from "@/features/layout";
import { useModal } from "@/features/modal/ModalStackManager";
import { ProductionPlanWithWorksForm } from "@/features/productionPlan/form/CreateForm";
import { Calendar, isCalendarDate } from "@/features/ui/Calendar";
import { DefectTypeItem, DefectTypeSet } from "@/features/ui/DefectTypeSet";
import customAlert from "@/features/ui/alert/alert";
import { EquipmentAutoComplete } from "@/features/ui/autoComplete/equipment/equipment-autoComplete";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { WorkUpdateForm } from "@/features/workByEquipment/components/WorkUpdateForm";
import { WorkActionBar } from "@/features/workByManagement/WorkActionBar";
import { WorkHeader } from "@/features/workByManagement/WorkHeader";
import { WorkNotFound } from "@/features/workByManagement/WorkNotFound";
import { WorkPassArea } from "@/features/workByManagement/WorkPassArea";
import { WorkProductionPlanTable } from "@/features/workByManagement/WorkProductionPlanTable";
import { WorkProductionPlanTableHead } from "@/features/workByManagement/WorkProductionPlanTableHead";
import { WorkProductionPlanTableRow } from "@/features/workByManagement/WorkProductionPlanTableRow";
import { WorkSiteSelect } from "@/features/workByManagement/WorkSiteSelect";
import { WorkTable } from "@/features/workByManagement/WorkTable";
import { WorkTableActionBar } from "@/features/workByManagement/WorkTableActionBar";
import { WorkTableBody } from "@/features/workByManagement/WorkTableBody";
import { WorkTableHead } from "@/features/workByManagement/WorkTableHead";
import { WorkTableRow } from "@/features/workByManagement/WorkTableRow";
import { WorkTableWrapper } from "@/features/workByManagement/WorkTableWrapper";
import useWorkMutation from "@/features/workByManagement/api/useWorkMutation";
import { workPage } from "@/features/workByManagement/api/useWorkPageQuery";
import { SocketData } from "@/features/workByManagement/context/WorkProvider";
import useAutoResetState from "@/features/workByManagement/hooks/useAutoResetState";
import { useWorkActions } from "@/features/workByManagement/hooks/useWorkActions";
import { useWorkState } from "@/features/workByManagement/hooks/useWorkState";
import { usePub } from "@/hooks";
import { useLoader } from "@/hooks/useLoader";
import useLocalStorage from "@/hooks/useLocalStorage";
import { BASE_URL } from "@/instance/axios";
import { getStartAndEndOfDateRange } from "@/utils/dateTimeUtil";
import styled from "@emotion/styled";
import { Button, Flex, Menu, Pagination, Select } from "@mantine/core";
import { AuthSignupPost201ResponseEquipmentWorksInner, DefaultApiWmsFlushPutRequest, ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar, IconSettings } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSocket } from "socket.io-react-hook";

const Work = {
    ActionBar: WorkActionBar,
    LeftActionBar: LeftActionBar,
    RightActionBar: RightActionBar,
    SiteSelect: WorkSiteSelect,
    Select: Select,
    Button: Button,
    WorkHeader: WorkHeader,
    WorkTableActionBar: WorkTableActionBar,
    WorkTableWrapper: WorkTableWrapper,
    WorkProductionPlanTable: WorkProductionPlanTable,
    WorkProductionPlanTableHead: WorkProductionPlanTableHead,
    WorkProductionPlanTableRow: WorkProductionPlanTableRow,
    WorkTable: WorkTable,
    WorkTableHead: WorkTableHead,
    WorkTableBody: WorkTableBody,
    WorkTableRow: WorkTableRow,
};

export const WorkByRows = () => {
    const state = useWorkState();
    const actions = useWorkActions();
    const [activePage, setPage] = useState(1);
    const { openModal } = useModal();
    const [defectTypes, setDefectTypes] = useLocalStorage<DefectTypeItem[][]>("defectTypes", [[], []]);
    // useAutoResetState를 사용하여 상태를 관리
    const [updateSuccess, setUpdateSuccess] = useAutoResetState<boolean>(false, 50);

    const publish = usePub();
    const { updateQuantityMutate, updateDefectMutate, deleteMutate, putEquipmentCorrectionMutate } = useWorkMutation();
    const { setLoading, LoadingOverlay } = useLoader();
    // const { mutate : flushMutate } = useInventoryMutation("flush");
    const queryClient = useQueryClient();
    const { mutate: flushMutate } = useMutation(
        (params: DefaultApiWmsFlushPutRequest) =>
            mutateInventories.flush(params).mutationFn(params as DefaultApiWmsFlushPutRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("inventories");
            }
        }
    )

    // const { data: workData, refetch } = useWorksGetQuery({
    //     defectCodes: defectTypes && defectTypes[1],
    //     activePage,
    //     query: {
    //         $and: [
    //             {
    //                 scheduledAt: {
    //                     $between: getStartAndEndOfDateRange(state.date[0], state.date[1])
    //                 }
    //             }
    //         ]
    //     }
    // });

    const [socketData, setSocketData] = useState<SocketData[]>([]);

    const { data: workData, refetch } = useQuery(
        workPage.workItem({
            defectCodes: defectTypes && defectTypes[1],
            query: {
                $and: [
                    {
                        // 최근 작업일 기준으로 변경 (2024-07-31 jswon)
                        lastWorkDate: {
                            $between: getStartAndEndOfDateRange(state.date[0], state.date[1])
                        }
                        // scheduledAt: {
                        //     $between: getStartAndEndOfDateRange(state.date[0], state.date[1])
                        // }
                    },
                    ...(state.equipmentCode ? [{
                        equipmentCode: {
                            $eq: state.equipmentCode
                        }
                    }] : []),
                    ...(state.itemCode ? [{
                        itemCode: {
                            $eq: state.itemCode
                        }
                    }] : [])
                ]
            },
            page: activePage,
            sort: "-id",
        })
    )
    const works = workData?.rows;

    const { socket, error } = useSocket(BASE_URL, {
        autoConnect: true,
    });

    useEffect(() => {
        actions.setResetState();
    }, []);

    useEffect(() => {
        works?.forEach((work) => {
            const roomName = work.equipmentCode ? `EC_${work.equipmentCode}` : "";
            socket.on(roomName, (message: SocketData) => {

                setSocketData((prev: SocketData[]) => {
                    const newData = [...prev, {
                        ...message,
                        workId: work.id
                    }];
                    return newData;
                })
            });

            socket.emit(
                "call",
                "socket.join",
                { room: roomName },
                function (err: any, res: any) {
                    if (err) {
                        console.error(err);
                    }
                }
            );
        });

        // 컴포넌트가 언마운트될 때 소켓의 이벤트 리스너를 제거
        return () => {
            works?.forEach((work) => {
                const roomName = work.equipmentCode ? `EC_${work.equipmentCode}` : "";
                socket.off(roomName);
            });
        };

    }, [socket, works]);

    useEffect(() => {
        if (works && works.length > 0) {
            actions.setTempWorks((prevWorks) => [...prevWorks, ...works]);
        }
    }, [works]);

    const handleUpdateQuantity = async (work: ProductionPlansGet200ResponseRowsInnerWorksInner | AuthSignupPost201ResponseEquipmentWorksInner) => {
        const quantityData = state.tempQuantity.find(data => data.key === (work.id && work.id.toString()));
        if (work.id && quantityData?.value) {
            try {
                await updateQuantityMutate({
                    workId: work.id,
                    worksWorkIdPerformancePutRequest: {
                        accQuantity: quantityData.value
                    }
                }, {
                    onSuccess: () => {
                        if (!(state.tempDefects.length)) {
                            actions.setTempQuantity([]);
                            setUpdateSuccess(true);  // 성공적인 API 호출 후 상태 업데이트
                            refetch(); // 데이터 다시 불러오기
                        }
                    }
                });
            } catch (error) {
                alert("변경되지 않은 수량이 존재합니다. 다시 시도해주세요.");
            }

        }
    };

    const handleUpdateDefects = async (work: ProductionPlansGet200ResponseRowsInnerWorksInner | AuthSignupPost201ResponseEquipmentWorksInner) => {
        const defectData = state.tempDefects.find(data => data.id === (work.id && work.id.toString()));
        // Update one defect at a time
        const updateDefectSequentially = async (defect: { key: string; value: string }) => {
            try {
                if (work.id) {
                    await updateDefectMutate({
                        workId: work.id,
                        worksWorkIdDefectPutRequest: {
                            defectCode: defect.key,
                            accQuantity: defect.value
                        }
                    });
                }
            } catch (e: any) {
                console.error("Error updating defect: ", e);
                throw e; // 에러를 다시 throw하여 밖에서 처리할 수 있도록 합니다.
            }
        };

        if (work.id && defectData) {
            try {
                await Promise.all(defectData.defect.map(defect => updateDefectSequentially(defect)));
                actions.setTempDefects([]);
                setUpdateSuccess(true);
                refetch(); // 데이터 다시 불러오기
            } catch (e: any) {
                if (e.response?.data?.message === "defectQuantity is greater than startQuantity") {
                    alert("불량 수량 저장에 실패했습니다. 불량 수량이 완료 수량을 초과하는지 다시 확인해주세요");
                } else {
                    alert("예상치 못한 오류가 발생했습니다. 나중에 다시 시도 해주십시오.");
                }
            }
        }
    };


    const { mutateAsync: inputERPWorkOrder } = usePostProductionPlanInputERPWorkOrder();

    // 생산계획 가져오기
    const getProductionPlan = async () => {
        try {
            const erpWorkOrder = await inputERPWorkOrder();

            // 쿼리를 다시 불러오게 하기
            queryClient.invalidateQueries('workPage');

            const { insert: workInsert, update: workUpdate, delete: workDelete } = erpWorkOrder.data ?? { insert: [], update: [], delete: [] };
            const insertCount = (Array.isArray(workInsert) ? workInsert.length : 0);
            const updateCount = (Array.isArray(workUpdate) ? workUpdate.length : 0);
            const deleteCount = (Array.isArray(workDelete) ? workDelete.length : 0);

            const alertParts = [
                insertCount ? insertCount + "건 연동" : "",
                updateCount ? updateCount + "건 갱신" : "",
                deleteCount ? deleteCount + "건 삭제" : ""
            ];
            const alertString = `생산계획 ${alertParts.filter(part => part).join(", ")}되었습니다.`;
            customAlert(alertString, '생산계획 연동', 'info');

        } catch (e) {
            console.log(e)
        }
    }

    const postERP = async () => {
        const workData = state.works ?? [];

        if (workData.length) {
            const workIds = workData.map(work => work.id);
            const workIdData = workIds.filter((workId): workId is number => workId !== undefined);

            setLoading(true);
            try {
                const result = await setERPWorkRecord({ workIds: workIdData });
                const { data } = result;

                if (data.success && data.success.length) {
                    const workStr = data.success.join(', ');
                    customAlert(`작업번호 ${workStr}번 실적이 성공적으로 전달되었습니다.`, '실적 전달', 'info')
                }

                if (data.fail && data.fail.length) {
                    const workStr = data.fail.join(', ');
                    customAlert(`작업번호 ${workStr}번 실적 전달이 실패했습니다.`, '실적 전달', 'red')
                }
            } catch (error) {
                console.error('실적 전달 중 오류 발생:', error);
                customAlert('실적 전달 중 오류가 발생했습니다.', '실적 전달', 'red');
            } finally {
                // 로딩 종료
                setLoading(false);
            }
            actions.setWorks((prevWorks) => []);
            setUpdateSuccess(true);
            publish('unCheck');
        }
    }

    return (
        <WorkExampleContainer>
            <LoadingOverlay />
            <Work.ActionBar>
                <Work.LeftActionBar>
                    {/* TODO: 현재 선택된 사업장 => 추가 필요 */}
                    {/* <Work.Select data={[]} placeholder="사업장 선택" /> */}
                    <Work.SiteSelect />
                    {/* TODO: 현재 선택된 라인 => 추후 테이블 추가 필요하고 equipment에 lineId 추가 필요  */}
                    <Work.Select data={[]} placeholder="라인 선택" />
                    <Work.Button
                        leftIcon={<IconCalendar />}
                        onClick={() => {
                            openModal(<Calendar dates={state.date} type="range" />, null, "날짜 선택", true)
                                .then((value) => { if (isCalendarDate(value)) actions.setDate(value) })
                        }
                        }
                    >날짜선택</Work.Button>
                </Work.LeftActionBar>
                <Work.RightActionBar>
                    <Menu shadow="md" width={150} position="bottom-end">
                        <Menu.Target>
                            <Button color="gray" rightIcon={<IconSettings />}>
                                Settings
                            </Button>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item onClick={() => {
                                openModal(<DefectTypeSet />, null, "불량 유형 설정").then((result) => {
                                    // result 데이터가 특정 데이터일 경우 불량 타입 재조회 (불량 유형 설정을 완료했다는 의미)
                                    if (Array.isArray(result)) {
                                        setDefectTypes(result as DefectTypeItem[][])
                                    }
                                })
                            }}>
                                Settings
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Work.RightActionBar>
            </Work.ActionBar>
            {
                (customFunctions.ADD_SETTING_EQUIPMENT_AUTOCOMPLETE || customFunctions.ADD_SETTING_ITEM_AUTOCOMPLETE) && <Flex gap="xs">
                    {
                        customFunctions.ADD_SETTING_EQUIPMENT_AUTOCOMPLETE &&
                        <EquipmentAutoComplete
                            onChange={(value) => {
                                actions.setEquipment(value);
                            }}
                            placeholder="설비를 선택해주세요"
                        />
                    }
                    {
                        customFunctions.ADD_SETTING_ITEM_AUTOCOMPLETE &&
                        <ItemAutoComplete
                            onChange={(value) => {
                                actions.setItem(value);
                            }}
                            placeholder="품목을 선택해주세요"
                            itemType="PRODUCT,SEMI_PRODUCT"
                        />
                    }
                </Flex>
            }
            <Work.WorkHeader dateType="range" />
            <Work.WorkTableActionBar>
                <Work.LeftActionBar>
                    <Work.Button onClick={() => openModal(
                        <ProductionPlanWithWorksForm />,
                        {},
                        "생산계획 생성",
                        true
                    ).then(() => refetch())}>생산계획 생성</Work.Button>
                    <Work.Button variant="light" disabled={!((state.works) && state.works.length === 1)} onClick={() => {
                        const [work] = state.works;
                        openModal(
                            <WorkInputForm<'plan'>
                                workRowData={work as ProductionPlansGet200ResponseRowsInnerWorksInner}
                            />,
                            null,
                            "원/부자재 투입",
                            true
                        );
                    }}>원/부자재 투입</Work.Button>
                    <Work.Button variant="light" color="orange" disabled={!((state.works) && state.works.length === 1)} onClick={() => {
                        const [work] = state.works;
                        flushMutate({
                            wmsFlushPutRequest: {
                                locationCode: (work as ProductionPlansGet200ResponseRowsInnerWorksInner)?.locationSetting?.toLocation?.code ?? ''
                            }
                        }, {
                            onSuccess: (res: any) => {
                                res.data.length > 0 ?
                                    customAlert(
                                        "재고가 초기화 되었습니다.",
                                        "재고 초기화",
                                        "info"
                                    ) : customAlert(
                                        "이미 초기화 되었습니다.",
                                        "재고 초기화",
                                        "red"
                                    )
                            }
                        })
                    }}>재고 초기화</Work.Button>
                    {
                        customFunctions.ADD_INPUT_ERP_WORK_ORDER &&
                        <Work.Button variant="outline" color="violet" onClick={getProductionPlan}>
                            생산계획 가져오기
                        </Work.Button>
                    }
                    {
                        customFunctions.ADD_ERP_WORK_RECORD &&
                        <Work.Button
                            disabled={!((state.works) && state.works.length >= 1)}
                            variant="outline" color="teal"
                            onClick={() => {
                                postERP();
                            }}
                        >
                            실적 전달하기
                        </Work.Button>
                    }
                    <Work.Button variant="light" color="teal" disabled={!((state.works) && state.works.length === 1)} onClick={async () => {
                        const [work] = state.works as (ProductionPlansGet200ResponseRowsInnerWorksInner)[];;
                        if (work.id && work.equipmentCode && work.trackingStatus === "WORKING") {
                            const result = await putEquipmentCorrectionMutate({
                                equipmentsCorrectionPutRequest: {
                                    // equipmentCode: work.equipmentCode
                                    workId: work.id
                                }
                            });
                            result.data.length ? customAlert(
                                "재고 보정이 완료되었습니다.",
                                "재고 보정",
                                "info"
                            ) : customAlert(
                                "보정할 재고가 존재하지 않습니다.",
                                "재고 보정",
                                "red"
                            )
                        } else {
                            customAlert(
                                "작업이 진행 중인 설비에 대해서만 재고 보정이 가능합니다.",
                                "재고 보정",
                                "red"
                            )
                        }
                    }}>재고 보정</Work.Button>
                </Work.LeftActionBar>
                <Work.RightActionBar>
                    <Work.Button color="yellow" disabled={!((state.works) && state.works.length === 1)} onClick={() => {
                        const [work] = state.works;
                        openModal(
                            <WorkUpdateForm<'plan'>
                                workRowData={work as ProductionPlansGet200ResponseRowsInnerWorksInner}
                            />,
                            null,
                            "작업 수정",
                            true
                        )
                    }}>수정</Work.Button>
                    <Work.Button color="red" disabled={!((state.works) && state.works.length >= 1)} onClick={() => {
                        if (window.confirm("선택한 작업을 삭제하시겠습니까?")) {
                            try {
                                state.works.forEach((work) => {
                                    work.id && deleteMutate({ workId: work.id });
                                });
                                actions.setWorks((prevWorks) => []);
                            } catch (e) {
                                alert("작업 삭제 중 오류가 발생했습니다. 다시 시도해주세요.");
                                console.error("Error deleting work: ", e);
                            }
                        }
                    }}>삭제</Work.Button>
                    {/* <Work.Button onClick={() => {
                        // doSomething
                        state.works.forEach(async (work) => {
                            await handleUpdateQuantity(work);
                            await handleUpdateDefects(work);
                        });
                    }}>모두 저장</Work.Button> */}
                    <Work.Button disabled={!((state.works) && state.works.length >= 1)}
                        onClick={() => {
                            state.works.forEach(async (work) => {
                                await handleUpdateQuantity(work);
                                await handleUpdateDefects(work);
                            });
                            // const [work] = state.works;
                            // await handleUpdateQuantity(work);
                            // await handleUpdateDefects(work);
                        }}>저장</Work.Button>
                </Work.RightActionBar>
            </Work.WorkTableActionBar>
            <Work.WorkTableWrapper>
                <Work.WorkTable isWithBorder={true}>
                    <Work.WorkTableHead pageKey="plan" defectTypes={defectTypes && defectTypes[1]} formReset={updateSuccess} />
                    <Work.WorkTableBody>
                        {/* WorkRow 영역 */}
                        {
                            works && works.length ? works?.map((work, index) => {
                                // 소켓 데이터를 해당 ID에 맞게 필터링
                                const socketDataObject = socketData.find((data) => data.workId === work.id);

                                return (
                                    <WorkPassArea<'plan'> pageKey="plan" socketData={socketDataObject} rowData={work} key={work.id || index} defectTypes={defectTypes && defectTypes[1]} formReset={updateSuccess} />
                                );
                            }) : <WorkNotFound defectLength={defectTypes ? defectTypes[1].length : 0} />
                        }
                    </Work.WorkTableBody>
                </Work.WorkTable>
            </Work.WorkTableWrapper>
            <Pagination
                onChange={(e) => { setPage(e); actions.setWorks((prevWorks) => []); }}
                value={activePage}
                total={workData?.totalPages as number}
                size="lg"
                radius="sm"
                w="100%"
                position="center"
            />
        </WorkExampleContainer >
    )
}

const WorkExampleContainer = styled.div`
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
`
